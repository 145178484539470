import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { authService } from '../auth/auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
regcode : any;
baseURL =environment.apiUrl;
status : any;
requestorid = environment.requestor_id;

  constructor(private router : Router,private activateRoute: ActivatedRoute,public _service: authService) { 

  //   this.activateRoute.queryParams.subscribe(params => {
  //     this.regcode = params['regcode'];   
  // });
  }

  ngOnInit(): void {
    console.log("regcode",localStorage.getItem('regcode'));
    this._service.getAuthentication(this.baseURL +'api/v1/checkauthn/' + localStorage.getItem('regcode')  +'.json?requestor='+ this.requestorid)
    .subscribe(res => {   
      if (res !== undefined){
        this.status = 'You are successfully authenticated. Please enjoy the program on your connected device.';
      }      
   },
    (err)=>{
          this.status = 'You are not authenticated. Please contact your service provider.';
  });   
  }
}
