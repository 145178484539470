import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class authService {
  public httpOptions : any;
  public http_Client : any;

  constructor(public _http : HttpClient) {}

  //Get getConfig API CALL
  public getConfig(baseUrl : any) {
    return this._http.get(baseUrl);
  };

  //Check Authentication 
  public getAuthentication(baseUrl : string) {
    return this._http.get(baseUrl);
  };
}