<div class="fullPageBG launchPage">
  <div  class="draft-king-logo"><img _ngcontent-cwo-c3="" alt="" src="../assets/images/draftkings-logo.png"></div>
  
  <h1>MASN STREAMING HAS LAUNCHED!</h1>
  <img  alt="MASN Logo" class="brandLogo" src="../assets/images/masn.svg">
  <label>Please enter the CODE displayed on your TV.
    </label>
  <div class="flSeachHead">   
    <form #autdetails="ngForm" (ngSubmit)="Authentication(autdetails.value)" novalidate>
      <div class="searchBox">
        <input type="text" class="input-text textbox" name="regCode" maxlength="7" placeholder="Code" autocomplete="off" value="" ngModel>
        <input type="submit" class="btn" value="submit">
      </div>
    </form>
  </div>
  
    
  
    <div class="boxesDesign" >
      <div class="bxListing" *ngFor="let item of mvpd; let i = index">
        <!-- <img   src="{{item.logoUrl.value}}" onerror="this.src='/../../assets/images/masn_op.svg';"  alt=" "> -->
        <span  (click)="getauth(item.id.value)">{{ item.displayName.value }}</span>
      </div>      
  </div>