import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from  '@angular/common/http';
import { authService } from '../auth/auth.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  baseURL = environment.apiUrl;
  mvpds : any;
  mvpd : any = [];
  public regCode:any;
  redirect_Url = environment.redirect_url;
  domain_name = environment.domain_Name;
  requestorid = environment.requestor_id;


  constructor(private _http: HttpClient, public _service: authService,public router: Router) {
   }

  ngOnInit() {
  }

  onSubmit(AuthDetails : any) {  
    
    this._service.getConfig(this.baseURL +'api/v1/config/MASN.json')
    .subscribe(output => {  
        this.mvpds = output;
        this.mvpds.requestor.mvpds.mvpd.forEach((record: any) => {
          this.mvpd.push(record.displayName);
        });          
  });
  }

  Authentication(data:any) {  
    this.mvpd = [];  
    console.log("this.mvpd",this.mvpd); 
    this.regCode=data.regCode;    
    this.regCode=this.regCode.toUpperCase();   
    if (this.regCode != null && this.regCode != '' && this.regCode.trim() !== '') {
    this._service.getConfig(this.baseURL +'api/v1/config/MASN.json')
    .subscribe(output => { 
       if(output != null) {
        this.mvpds = output;
        this.mvpds.requestor.mvpds.mvpd.forEach((record: any) => {
          this.mvpd.push(record);
        });  
        localStorage.setItem('regcode',this.regCode);   
      }      
  },
    (err)=>{
          console.log(err.status);
  }); 
}
  
  }

 public getauth(id:any) {
   
      window.location.href= this.baseURL + "api/v1/authenticate?domain_name="+ this.domain_name +"&mso_id=" + id + "&reg_code=" + this.regCode + "&requestor_id=" + this.requestorid +"&noflash=true&no_iframe=true&redirect_url=" + this.redirect_Url +"callback";
  }   
}
